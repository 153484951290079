<template>
    <dashboard>
        <el-row :gutter="15" v-if="show">
            <el-col :xs="24">
                <el-row :gutter="15">
                    <el-col :xs="24" :sm="5">
                        <div class="back">
                            <a href="#" class="el-button el-button--info mb-3" @click="$router.go(-1)">
                                <i class="el-icon-back mr-2"/>Back
                            </a>
                        </div>
                    </el-col>
                    <!-- <el-col :xs="24" :sm="19">
                        <share-social :title="show.title"/>
                    </el-col> -->
                </el-row>
                <hr>
                <div class="jobview freelancer">
                    <el-card>
                        <div class="header">
                            <center>
                                <h4 class="orange-text">{{ show.title }}</h4>
                                <div class="overview">
                                    <center>
                                        <h5>
                                            <i class="el-icon-money"/> {{ jobPricing(show) }} | <i
                                                class="el-icon-timer"/> {{ show.job_type | capitalizeFirst }}
                                        </h5>
                                    </center>
                                </div>
                                <!--              <p class="small mt-3 mb-0 gray-text">Date posted: March 03, 2020</p></center>-->
                                <p class="small mt-3 mb-0 gray-text">Date posted: {{ show.created_at | usDateFormat }}</p>
                            </center>
                        </div>
                        <div class="body pt-0">
                            <div class="apply text-center">
                <span v-if="! show.application">
                  <p v-if="hasIncompleteProfile">
                    Please complete your profile to apply for this job.
                    <br>
                    <small>
                      <i>
                        (<router-link to="/accountdetails">Title, Introduction, Bio, Pricing</router-link>
                        and
                        <router-link to="/accountdetails#nameandemail">Profile Photo</router-link>)
                      </i>
                    </small>
                  </p>
                  <router-link v-if="hasIncompleteProfile" to="/accountdetails">
                    <el-button
                            type="warning"
                            class="mr-1"
                    >Update Profile</el-button>
                  </router-link>
                  <router-link v-else :to="`/jobs/apply/${job_id}`">
                    <el-button
                            type="warning"
                            class="mr-1">
                        Apply for this job
                    </el-button>
                  </router-link>
                </span>
                    <span v-if="show.application">
                        <el-button type="info" class="mr-1" disabled
                             @click="$router.push('/jobs/apply/' + job_id + '/' + f_id )">
                                Applied
                        </el-button>
                </span>

                                <el-button
                                        v-if="isBookmarked"
                                        type="warning"
                                        plain
                                        @click="unBookMark({ job_id: tempRouteId})"
                                >
                                    Remove bookmark
                                </el-button>
                                <el-button v-else type="warning" plain @click="bookmark({ job_id: tempRouteId })">
                                    Bookmark this job
                                </el-button>
                            </div>
                            <div class="details">
                                <h5 class="orange-text bold mb-3">Overview</h5>
                                <div class="intro">
                                    <p v-text="show.intro"/>
                                </div>
                                <div class="description mb-3">
                                    <span class="bold">Description:</span> <span v-html="show.description"/>
                                </div>
                                <hr v-if="show.skill && show.skill.length > 0">
                                <div v-if="show.skill && show.skill.length > 0" class="skills mt-3 mb-4">
                                    <h5 class="orange-text bold mb-3">Skills</h5>
                                    <skills-list :list="show.skill"/>
                                </div>
                                <hr v-if="show.employer">
                                <div v-if="show.employer" class="skills mt-3">
                                    <h5 class="orange-text bold mb-4">About the employer</h5>
                                    <el-row v-if="show.employer.company" :gutter="20" class="align-center mb-3">
                                        <el-col v-if="show.employer && show.employer.company && show.employer.company.logo"
                                                :xs="6" :sm="4" :md="4">
                                            <div class="image-card">
                                                <div class="image-border">
                                                  <div class="image-content" :style="`background-image: url(${companyLogo(show.employer.company)});`">
                                                  </div>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :xs="18" :sm="20" :md="20">
                                            <h5 class="mb-0 ml-1"> {{ show.employer.company.company_name }}</h5>
                                            <!--                      <p class="mb-1 gray small ml-1">Member since: February 02, 2020</p>-->
                                        </el-col>
                                    </el-row>
                                    <p class="m-0">{{ show.employer.company.intro }} </p>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </el-col>
        </el-row>

      <deactivated v-else></deactivated>
    </dashboard>
</template>

<script>
    import moment from 'moment'
    import store from '../../vuex/store'
    import {mapActions, mapGetters} from 'vuex'
    import {LOCAL_BASE_URL} from '../../vuex/utils/constant'
    import Dashboard from '~/components/Dashboard/Dashboard'
    // import ShareSocial from '~/components/ShareSocial'
    import SkillsList from '~/components/Skills/List'
    import showsJobDetails from '~/mixins/showsJobDetails'
    import showsCompanyLogo from '~/mixins/showsCompanyLogo'
    import Deactivated from "@/views/Freelancer/Jobs/Deactivated";

    export default {
        store,

        components: {Dashboard, SkillsList, Deactivated},

        mixins: [showsCompanyLogo, showsJobDetails],
        
        filters: {
            usDateFormat (value) {
                return moment(value).format('LL');
            },
        },
        
        data() {
            return {
                url: LOCAL_BASE_URL,
                value: 5,
                job_id: this.$route.params.job_id,
                f_id: this.$route.params.f_id,
                tempRouteId: this.$route.params.job_id - 10000,
            }
        },
        computed: {
            ...mapGetters({
                error: 'getJobsError',
                show: 'getJobsDetails',
                info: 'getFreelancerInfo',
            }),

            isBookmarked() {
                if (!this.show) {
                    return false
                }
                return this.show.bookmarks.length > 0
            },

            hasIncompleteProfile() {
                const info = this.info
                const profile = info.profile
                if (!profile) {
                    return true
                }
                return !profile.intro
                    || !profile.description
                    || !profile.title
                    || !info.profile_photo
                    || (!profile.full_time_ava
                        && !profile.part_time_ava
                        && !profile.hourly_ava)
            },
        },

      mounted() {
            this.$store.dispatch('jobView', {job_id: this.tempRouteId})
            this.$store.dispatch('freelancerGet')
            this.$store.dispatch('freelancerMailingUpdate', {
                job_id : this.tempRouteId,
                clicked : true
            })
        },

        methods: {
            ...mapActions({
                bookmark: 'jobBookMark',
                unBookMark: 'jobUnBookMark',
            }),
        },
    }
</script>
<style scoped lang="scss">
    
</style>

